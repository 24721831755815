import React from 'react';
import Authenticate from '../../../components/Identity/Authenticate';
import * as styles from '../../../styles/admin/Account.module.scss';
import Layout from '../../../components/Admin/Layout';
import CatalogTabView from '../../../components/Admin/Browse/CatalogTabView';
import browseTabs from '../../../browseTabs';
import { AdminProvider } from '../../../context/Admin/AdminContext';
import { GrowthToolTabs } from '../../../types/commons';

type Props = {
  identityProviderClient: any,
}

const CatalogPage:React.FC<Props> = ({ identityProviderClient }) => (
  <AdminProvider>
    <div className={styles.accountPageWrapper}>
      <Layout
        title="Growth Tools"
        Component={() => <CatalogTabView currentTab={GrowthToolTabs.HIDE_COURSES} />}
        tabs={browseTabs}
        activeTab={GrowthToolTabs.HIDE_COURSES}
        identityProviderClient={identityProviderClient}
      />
    </div>
  </AdminProvider>
);

export default Authenticate(CatalogPage);
